<template>
  <v-container fluid>
    <v-row justify="center" v-if="dialogWarn">
      <v-dialog v-model="dialogWarn" max-width="300">
        <v-card>
          <v-card-text>
            <v-text-field
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              v-model="access.code"
              :type="value ? 'password' : 'text'"
              class="mb-2"
              label="Saisir le code d’accès d’ouverture de l’Offre de la Proposition ou du Pli"
              required
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn color="primary" block @click="CheckIt">
              OUVRIR
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row v-if="profile == 2">
      <v-col cols="12" md="6" class="px-0 py-0">
        <v-card class="mt-4">
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h4" v-bind="attrs" v-on="on"
                  ><h4>
                    Soumette une Offre/Proposition/Pli
                  </h4></span
                >
              </template>
              <span
                >Soumette une Offre/Proposition/Pli</span
              >
            </v-tooltip>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <template>
              <v-container fluid>
                <v-radio-group v-model="Dtype" row>
                  <v-radio
                    label="je soumets une Offre/Propositions/Plis"
                    v-bind:value="false"
                    class="mb-2"
                  ></v-radio>
                  <v-radio
                    label="j'envoie ma réponse à une manifestation d'intérêt/ un avis de pré-qualification"
                    v-bind:value="true"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>

            <v-autocomplete
              v-if="!Dtype"
              v-model="offer.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].docs"
              item-text="object"
              v-on:change="NeedCode()"
              item-value="id"
              outlined
              class="mt-2"
              label="Sélectionner le dossier concerné par l’Offre/Proposition/Pli "
            ></v-autocomplete>
            <v-autocomplete
              v-if="Dtype"
              v-model="offer.document"
              :items="this.$store.getters['Auth/getlistSubmissionFoldNoti'].notice_ami_apq"
              item-text="object"
              item-value="id"
              outlined
              label="Sélectionner l’avis concerné par la réponse"
            ></v-autocomplete>
            <v-file-input
              v-if="!show2ndFile"
              v-model="offer.file"
              multiple
              :label="
                ` ${
                  !Dtype
                    ? 'Joindre le fichier relatif à l’Offre/Propositions/Pli'
                    : 'Joindre le fichier relatif à la réponse à l’avis'
                }`
              "
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-file-input
              v-if="show2ndFile"
              v-model="offer.file"
              multiple
              label="Joindre le fichier relatif à la proposition technique"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-file-input
              v-if="show2ndFile"
              v-model="offer.file_dp"
              multiple
              label="Joindre le fichier relatif à la proposition financière"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <v-text-field
              v-model="offer.rep_firstname"
              label="Nom du représentant habilité du soumissionnaire "
              v-if="!Dtype"
            ></v-text-field>
            <v-text-field
              v-if="!Dtype"
              v-model="offer.rep_lastname"
              label="Prénoms du représentant habilité du soumissionnaire "
            ></v-text-field>
            <v-text-field
              v-if="!Dtype"
              v-model="offer.rep_title"
              label="Fonction du représentant habilité du soumissionnaire "
            ></v-text-field>
            <v-text-field
              v-model="offer.rep_email"
              type="tel"
              label="E-mail du représentant habilité du soumissionnaire"
            ></v-text-field>
            <vue-tel-input-vuetify
              v-bind="bindProps"
              placeholder="Numéro de téléphone du représentant habilité du soumissionnaire"
              class="mb-1"
              v-model="offer.rep_phone"
            ></vue-tel-input-vuetify>
            <v-text-field
              class="mb-1"
              v-if="NeedAccess"
              v-model="offer.accces_code"
              :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (value = !value)"
              :type="value ? 'password' : 'text'"
              label="Code d'accées au dossier *"
              required
            ></v-text-field>
            <v-row dense>
              <v-col
                ><v-btn color="primary" large class="white--text button__full" :block="$vuetify.breakpoint.smAndDown" @click="addOffer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="h6" v-bind="attrs" v-on="on"
                        >SOUMETTRE MON OFFRE/PROPOSITIONS/PLI</span
                      >
                    </template>
                    <span
                      ><h4>
                        Envoyer l'Offres/Propositions ou la réponse à l'avis à manifestation
                        d'intérêt/ avis de pré-qualification
                      </h4></span
                    >
                  </v-tooltip>
                </v-btn></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-col  class="px-0 py-0">
          <v-card class="mx-auto">
            <v-card-title>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <span class="h6" v-bind="attrs" v-on="on"
                    ><h4>
                      Liste détaillée de mes Offres/Propositions/Plis
                    </h4></span
                  >
                </template>
                <span
                  >Liste détaillée de mes Offres/Propositions/Plis </span
                >
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-chip class="ma-2">{{ this.$store.getters["Auth/getOfferList"].length }}</v-chip>
            </v-card-title>

            <v-divider></v-divider>
            <v-virtual-scroll
              :items="this.$store.getters['Auth/getOfferList']"
              :item-height="50"
              height="400"
            >
              <template v-slot="{ item }">
                <v-list-item>
                  <v-list-item-avatar>
                    <v-icon large>mdi-toolbox-outline</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-if="item.document">
                      {{ item.document.object }}</v-list-item-title
                    >
                    <v-list-item-title v-if="item.notice">{{
                      item.notice.object
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.createdAt | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn @click="seeoffer(item)" depressed small>
                      Acceder
                      <v-chip outlined label class="mx-1" v-if="item.propFin">PF</v-chip>
                      <v-chip label outlined class="mx-1" v-if="item.propTech">PT</v-chip>
                      <v-icon color="orange darken-4" right>mdi-eye</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
    <v-row v-if="profile != 2">
      <v-col>
        <v-card>
          <v-card-title>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span class="h6" v-bind="attrs" v-on="on"
                  ><h4>Liste des offres-propositions-plis</h4></span
                >
              </template>
              <span>Liste des offres-propositions-plis </span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-text-field
              v-if="Dtype"
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherchez les réponses aux Avis à Manifestation d’Intérêts / Avis de Pré - qualification"
              single-line
              hide-details
            ></v-text-field>
            <v-text-field
              v-if="!Dtype"
              v-model="search"
              append-icon="mdi-magnify"
              label="Recherchez un(e) Offre/Proposition/Pli"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <template>
              <v-container fluid>
                <v-radio-group v-model="Dtype" row>
                  <v-radio label="Aperçu spécifique des Offres/Propositions/Plis reçus" v-bind:value="false"></v-radio>
                  <v-radio
                    label="Voir les réponses aux avis à
                       manifestation d'intérêts/ avis de pré-qualification reçus"
                    v-bind:value="true"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </template>
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-if="Dtype"
              locale="fr"
              :search="search"
              :headers="headersA"
              @click:row="goto"
              no-data-text="Aucune offres disponible"
              :items="
                this.$store.getters['Auth/getOfferList'].filter(function(notice) {
                  return notice.notice != null;
                })
              "
              sort-by="createdAt"
              group-by="notice.object"
              class="elevation-1 mb-5 px-5"
            >
              <template v-slot:[`group.header`]="{ headers, items, isOpen, toggle }">
                <td :colspan="headers.length">
                  <v-icon @click="toggle"
                    >{{ isOpen ? "mdi-arrow-down-drop-circle" : "mdi-arrow-up-drop-circle" }}
                  </v-icon>
                  <v-chip>
                    {{ items[0].notice.object }}
                  </v-chip>
                  Ouverture le :
                  {{ items[0].notice.deadline | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                </td>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                {{ item.id + 17 }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon
                  medium
                  v-if="new Date() <= new Date(item.notice.deadline)"
                  color="red darken-2"
                  >mdi-lock</v-icon
                >
                <v-icon
                  medium
                  v-if="new Date() >= new Date(item.notice.deadline)"
                  color="green darken-2"
                  >mdi-lock-open</v-icon
                >
              </template>
              <template v-slot:[`item.openSession`]="{ item }">{{
                item.openSession
                  ? item.openSession
                  : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
              }}</template>
              <template v-slot:[`item.createdAt`]="{ item }">{{
                item.createdAt ? item.createdAt : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
              }}</template>
            </v-data-table>
            <v-data-table
              v-if="!Dtype"
              locale="fr"
              :search="search"
              :headers="headers"
              @click:row="goto"
              no-data-text="Aucune Offres disponible"
              :items="
                this.$store.getters['Auth/getOfferList'].filter(function(doc) {
                  return doc.document != null;
                })
              "
              sort-by="createdAt"
              group-by="document.object"
              class="elevation-1 mb-5 px-5"
            >
              <template v-slot:[`group.header`]="{ headers, items, isOpen, toggle }">
                <td :colspan="headers.length">
                  <v-icon @click="toggle"
                    >{{ isOpen ? "mdi-arrow-down-drop-circle" : "mdi-arrow-up-drop-circle" }}
                  </v-icon>
                  <v-chip>
                    {{ items[0].document.object }}
                  </v-chip>
                  <span v-show="!items[0].dpSelectionQuality">
                    Ouverture :
                    {{ items[0].document.date_opening | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                  </span>
                  <span v-show="items[0].dpSelectionQuality">
                    <v-chip outlined label class="mx-1">PF</v-chip> Ouverture financiere le :
                    {{ items[0].openDatPropFin | moment("dddd, Do MMMM YYYY à HH:mm:ss A") }}
                  </span>
                </td>
              </template>
              <template v-slot:[`item.id`]="{ item }">
                {{ item.id + 17 }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon
                  medium
                  v-if="new Date(item.document.date_opening) < new Date()"
                  color="green darken-2"
                  >mdi-lock</v-icon
                >
                <v-icon
                  medium
                  v-if="new Date(item.document.date_opening) > new Date()"
                  color="red darken-2"
                  >mdi-lock-open</v-icon
                >
              </template>
              <template v-slot:[`item.openSession`]="{ item }">{{
                item.openSession
                  ? item.openSession
                  : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
              }}</template>
              <template v-slot:[`item.createdAt`]="{ item }">
                <v-chip outlined label class="mx-1" v-if="item.propFin">PF</v-chip>
                <v-chip label outlined class="mx-1" v-if="item.propTech">PT</v-chip>
                {{
                  item.createdAt ? item.createdAt : "--" | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
                }}</template
              >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { icp_request } from "../../../../config/axios";

export default {
  data: () => ({
    search: "",
    bindProps: {
      mode: "international",
      required: true,
      label: "Numéro de téléphone du représentant habilité du soumissionnaire",
      enabledCountryCode: true,
      validCharactersOnly: true,
      enabledFlags: true,
      autocomplete: "off",
      name: "telephone",
      maxLen: 25,
      inputOptions: {
        showDialCode: true
      }
    },
    Dtype: false,
    headers: [
      {
        align: "start",
        value: "id",
        text: "#"
      },
      {
        text: "Statuts",
        sortable: true,
        value: "status"
      },
      {
        text: "Nom des soumissionnaires",
        sortable: true,
        value: "repFirstname"
      },
      {
        text: "E-mail des soumissionnaires",
        align: "start",
        sortable: true,
        value: "createdBy.email"
      },
      {
        text: "Noms des représentants habilités",
        sortable: true,
        value: "repFirstname"
      },
      {
        text: "E-mail représentants habilités",
        sortable: true,
        value: "repEmail"
      },
      {
        text:
          "Date/heure de réception des Offres",
        sortable: true,
        value: "createdAt"
      },
      { text: "Dossier", value: "document.object" }
    ],
    headersA: [
      {
        align: "start",
        value: "id",
        text: "#"
      },
      {
        text: "Statuts des réponses aux Avis à Manifestation d’Intérêts / Avis de Pré – qualification en rapport avec la date limite d’ouverture",
        sortable: true,
        value: "status"
      },
      {
        text: "Email des candidats/Soumissionnaires",
        align: "start",
        sortable: false,
        value: "createdBy.email"
      },
      {
        text: "Nom des représentants habilités des candidats/Soumissionnaires",
        sortable: true,
        value: "repFirstname"
      },
      {
        text: "E-mail des représentants habilités des candidats/Soumissionnaires",
        sortable: true,
        value: "repEmail"
      },
      {
        text: "Date/heure de réception des réponses aux Avis à Manifestation d’Intérêts / Avis de Pré – qualification",
        sortable: true,
        value: "createdAt"
      },
      { text: "Avis", value: "notice.object" }
    ],
    dialogWarn: false,
    show2ndFile: false,
    NeedAccess: false,
    access: {
      offer: 0,
      code: ""
    },
    offer: {
      document: 0,
      rep_firstname: "",
      rep_lastname: "",
      rep_title: "",
      rep_phone: "",
      accces_code: "",
      rep_email: "",
      file: [],
      file_dp: []
    }
  }),
  created() {
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllOffer");
    this.$store.dispatch("Auth/loadAllDoc");
    this.collapseAll;
  },
  methods: {
    ...mapActions({
      extension_addN: "Auth/extension_addN"
    }),
    collapseAll() {
      this.$refs.expandableTable.collapseAll();
    },
    expandAll() {
      this.$refs.expandableTable.expandAll();
    },
    addOffer() {
      const data = new FormData();
      if (this.Dtype) {
        data.append("notice", this.offer.document);
      } else {
        data.append("document", this.offer.document);
      }
      if (this.show2ndFile) {
        data.append("file_dp", this.offer.file_dp[0]);
      }
      data.append("rep_firstname", this.offer.rep_firstname);
      data.append("rep_lastname", this.offer.rep_lastname);
      data.append("rep_title", this.offer.rep_title);
      data.append("rep_phone", this.offer.rep_phone);
      data.append("rep_email", this.offer.rep_email);
      if (this.offer.accces_code) {
        data.append("folder_code_check", this.offer.accces_code);
      }
      data.append("file", this.offer.file[0]);
      this.$store.dispatch("Auth/addOffer", data);

      this.offer.document = 0;
      this.offer.rep_firstname = "";
      this.offer.rep_lastname = "";
      this.offer.rep_title = "";
      this.offer.rep_phone = "";
      this.offer.accces_code = "";
      this.offer.rep_email = "";
      this.offer.file = [];
      this.offer.file_dp = [];
    },
    seeoffer(item) {
      console.log(item);
      this.$router.push({ name: "showOffers", params: { id: item.id } });
    },
    NeedCode() {
      if (this.offer.document) {
        let offer = this.offer.document;
        let filtered = this.$store.getters["Auth/getListDoc"].filter(function(value, index, arr) {
          return value.id == offer;
        });
        // console.log(filtered);
        this.NeedAccess = !filtered[0].free;
      }
      this.DpStyle();
    },
    DpStyle() {
      if (this.offer.document) {
        let offer = this.offer.document;
        let filtered = this.$store.getters["Auth/getListDoc"].filter(function(value, index, arr) {
          return value.id == offer;
        });
        if (filtered[0].documentType.status == 4) {
          this.show2ndFile = true;
        } else {
          this.show2ndFile = false;
        }
      }
    },
    goto(value) {
      const { id, document, notice } = value;
      console.log(notice);
      if (document) {
        if (new Date() <= new Date(document.date_opening)) {
          Swal.fire({
            title: "Attention !",
            text: `Impossible d’ouvrir le fichier avant la date/l’heure d’ouverture des Offres/Propositions/Plis prévue pour le ${moment(document.date_opening).format(
              "dddd, Do MMMM YYYY à HH:mm:ss A"
            )}`,
            icon: "error"
          });
        } else {
          this.access.offer = id;
          this.dialogWarn = !this.dialogWarn;
        }
      }
      if (notice.noticeType.status == 6 || notice.noticeType.status == 7) {
        if (new Date() <= new Date(notice.deadline)) {
          Swal.fire({
            title: "Attention !",
            text: `L'ouverture des plis est prévue pour le ${moment(notice.deadline).format(
              "dddd, Do MMMM YYYY à HH:mm:ss A"
            )}`,
            icon: "error"
          });
        } else {
          this.$router.push({ name: "showOffers", params: { id: parseInt(id) } });
        }
      } else {
        if (new Date() < new Date(notice.deadline)) {
          Swal.fire({
            title: "Attention !",
            text: `L'ouverture des plis est prévu pour le ${moment(notice.deadline).format(
              "dddd, Do MMMM YYYY à HH:mm:ss A"
            )}`,
            icon: "error"
          });
        } else {
          this.access.offer = id;
          this.dialogWarn = !this.dialogWarn;
        }
      }
    },
    async CheckIt() {
      const data = new FormData();
      data.append("offer", this.access.offer);
      data.append("code", this.access.code);
      if (this.access.code) {
        await icp_request
          .post("offer/auth_check", data, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          })
          .then(response => {
            if (response.data.status === 0) {
              //console.log(response.data)
              if (response.data.data.is_correct) {
                this.$router.push({ name: "showOffers", params: { id: this.access.offer } });
              }
            } else {
              this.$store.dispatch("Auth/Notify_Pop", response);
            }
          });
      }
    }
  }
};
</script>
<style>
.fullWidth {
}
.mx-datepicker {
  width: auto;
}
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
</style>
