<template>
  <v-container fluid>
    <v-row v-if="profile == 2 && this.$store.getters['Auth/get_selected_offer'].notice">
      <v-col cols="12" md="5">
        <v-card color="primary" class="mb-3">
          <v-card-title class="white--text">
            <v-icon color="white">mdi-bell-ring</v-icon> <v-spacer></v-spacer>
            <h4>Séance prévu {{ getRemaining }}</h4>
          </v-card-title>
        </v-card>
        <v-card class="pa-2">
          <v-card-title>
            <h4>Résumé des informations sur la réponse à l’avis</h4>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Email du représentant :</b>
            <v-text-field
              v-model="offerU.repEmail"
              class="mb-2"
              :label="this.$store.getters['Auth/get_selected_offer'].repEmail"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <b>Téléphone du représentant :</b>
            <v-text-field
              class="mb-2"
              v-model="offerU.repPhone"
              :label="this.$store.getters['Auth/get_selected_offer'].repPhone"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <v-file-input
              v-model="offerU.file"
              multiple
              label="Joindre une nouvelle réponse à l’avis (En cas de modification)"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <small>NB : Actualiser les informations ci-dessus (si nécessaire)</small>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="UpdateOffer">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>CONFIRMER LA MODIFICATION DE MA REPONSE
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-3">
          <v-card-title
            >Faire le retrait de ma réponse <v-spacer></v-spacer
            ></v-card-title
          >
          <v-card-text>
            <v-textarea
              v-model="reason"
              filled
              label="Saisir les raisons éventuelles du retrait de ma réponse"
              value="Votre message ici"
            ></v-textarea>
          </v-card-text>
          <v-card-actions class="custom-center">
            <v-btn @click="openConfirmationDialog" class="red white--text">
              CONFIRMER LE RETRAIT DEFINITIF
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-dialog v-model="confirmationDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          Êtes-vous sûr de vouloir retirer cette offre ?
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeConfirmationDialog">Annuler</v-btn>
          <v-btn text @click="confirmOfferRemoval" class="red darken-1">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="mb-8" v-if="this.$store.getters['Auth/get_selected_offer'].notice">
          <v-card-title>
            <h4>
              Avis concerné
              <!--{{ this.$store.getters["Auth/get_selected_offer"].notice.noticeType.description }}-->
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item class="block text-truncate">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="this.$store.getters['Auth/get_selected_offer'].notice.object"
                  ></v-list-item-title>

                  <v-list-item-subtitle class="text-truncate secondary--text">{{
                    this.$store.getters["Auth/get_selected_offer"].notice.publiDate
                      | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            <h4>Aperçu de la réponse à l’avis</h4>
            <v-spacer></v-spacer>
            <v-chip
              >Le
              {{
                this.$store.getters["Auth/get_selected_offer"].createdAt
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
              }}</v-chip
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
              "
              :value="this.$store.getters['Auth/get_selected_offer'].fileLink"
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
              :src="this.$store.getters['Auth/get_selected_offer'].fileLink"
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="profile == 2 && this.$store.getters['Auth/get_selected_offer'].document">
      <v-col cols="12" md="5">
        <v-card color="primary" class="mb-3" v-if="!this.$store.getters['Auth/get_selected_offer'].propFin">
          <v-card-title class="white--text">
            <v-icon color="white">mdi-bell-ring</v-icon> <v-spacer></v-spacer>
            <h4>Séance prévu {{ getRemaining }}</h4>
          </v-card-title>
        </v-card>
        <v-card color="primary" class="mb-3" v-if="this.$store.getters['Auth/get_selected_offer'].propFin">
          <v-card-title class="white--text">
            <v-icon color="white">mdi-bell-ring</v-icon> <v-spacer></v-spacer>
            <h4>Séance prévu {{ this.$store.getters['Auth/get_selected_offer'].openDatPropFin | moment("dddd, Do MMMM YYYY à HH:mm:ss") }}</h4>
          </v-card-title>
        </v-card>
                <div v-show="this.$store.getters['Auth/get_selected_offer'].dpSelectionQuality">
          <v-card class="pa-2 mt-2" v-if="this.$store.getters['Auth/get_selected_offer'].propTech && !this.$store.getters['Auth/get_selected_offer'].hasDpFile">
            <v-card-title>
              <h4>Transmettre la Proposition Financière (qualité technique uniquement)</h4>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-spacer class="my-1"></v-spacer>
              <v-file-input
                v-model="file_for_dp"
                multiple
                label="Joindre la Proposition Financière"
                truncate-length="15"
              >
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
              <small>Notez Bien (NB) : Seul le soumissionnaire retenu suite à l’évaluation des propositions techniques pourra soumettre sa Proposition Financière</small>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="white--text" block @click="UpdateDpFile">
                <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>SOUMETTRE LA PROPOSITION FINANCIERE
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
        <v-card class="pa-2">
          <v-card-title>
            <h4>Résumé des informations sur l’Offre/Proposition/Pli soumis(e)</h4>
            <!--
              <h4>Résumé des informations sur la Proposition</h4>
            -->
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <b>Nom du représentant :</b>
            <v-text-field
              v-model="offerU.repFirstname"
              class="mb-2"
              :label="this.$store.getters['Auth/get_selected_offer'].repFirstname"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <b>Prénom du représentant :</b>
            <v-text-field
              v-model="offerU.repLastname"
              class="mb-2"
              :label="this.$store.getters['Auth/get_selected_offer'].repLastname"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <b>Titre du représentant :</b>
            <v-text-field
              class="mb-2"
              v-model="offerU.repTitle"
              :label="this.$store.getters['Auth/get_selected_offer'].repTitle"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <b>Email du représentant :</b>
            <v-text-field
              v-model="offerU.repEmail"
              class="mb-2"
              :label="this.$store.getters['Auth/get_selected_offer'].repEmail"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <b>Téléphone du représentant :</b>
            <v-text-field
              class="mb-2"
              v-model="offerU.repPhone"
              :label="this.$store.getters['Auth/get_selected_offer'].repPhone"
              required
            ></v-text-field>
            <v-spacer class="my-1"></v-spacer>
            <v-file-input
              v-model="offerU.file"
              multiple
              label=" Joindre une nouvelle Offre/Proposition/Pli (En cas de modification)"
              truncate-length="15"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">{{ text }}</v-chip>
              </template>
            </v-file-input>
            <small>NB : Actualiser les informations ci-dessus (si nécessaire)</small>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" class="white--text" block @click="UpdateOffer">
              <v-icon class="mr-2" dark>mdi-cloud-upload</v-icon>
               CONFIRMER LA MODIFICATION DE MON OFFRE
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="mt-3">
          <v-card-title>
        Faire le retrait de mon Offre/Proposition/Pli
        <v-spacer></v-spacer>
      </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="reason"
              filled
              label="Saisir les raisons éventuelles du retrait de mon Offre/Proposition/Pli"
              value="Votre message ici"
            ></v-textarea>
          </v-card-text>
      <v-card-actions class="custom-center">
        <v-btn @click="openConfirmationDialog" class="red white--text">
              CONFIRMER LE RETRAIT DEFINITIF
        </v-btn>
      </v-card-actions>
        </v-card>
        <v-dialog v-model="confirmationDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirmation</v-card-title>
        <v-card-text>
          Êtes-vous sûr de vouloir retirer cette offre ?
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeConfirmationDialog">Annuler</v-btn>
          <v-btn text @click="confirmOfferRemoval" class="red darken-1">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="mb-8">
          <v-card-title>
            <h4>
              <!--Demande d’appel de Propositions concerné-->
              <!--Dossier d’appel à concurrence concerné-->
              <!--
                {{ this.$store.getters["Auth/get_selected_offer"].document.documentType.description }}
              -->
              Dossier concerné
            </h4>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-list-item class="block text-truncate">
                <v-list-item-content>
                  <v-list-item-title
                    class="primary--text text-truncate font-weight-bold"
                    v-text="this.$store.getters['Auth/get_selected_offer'].document.object"
                  ></v-list-item-title>

                  <v-list-item-subtitle class="text-truncate secondary--text">{{
                    this.$store.getters["Auth/get_selected_offer"].document.publiDate
                      | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-title>
            <h4>Aperçu de l’Offre/Proposition/Pli soumis(e)</h4>
            <!--
              <h4>Aperçu de la Proposition soumise</h4>
            -->
            <v-spacer></v-spacer>
            <v-chip
              outlined
              label
              class="mx-1"
              v-if="this.$store.getters['Auth/get_selected_offer'].propFin"
              >PF</v-chip
            >
            <v-chip
              label
              outlined
              class="mx-1"
              v-if="this.$store.getters['Auth/get_selected_offer'].propTech"
              >PT</v-chip
            >
            <v-spacer></v-spacer>
            <v-chip
              >Le
              {{
                this.$store.getters["Auth/get_selected_offer"].createdAt
                  | moment("dddd, Do MMMM YYYY à HH:mm:ss A")
              }}</v-chip
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height:62em !important;overflow:hidden" v-if="this.$store.getters['Auth/get_selected_offer'].document.documentType.status == 4">
            <VueDocPreview
              style="height:100%"
              v-if="
                this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
              "
              :value="
                this.$store.getters['Auth/get_selected_offer'].propTech
                  ? this.$store.getters['Auth/get_selected_offer'].fileLink
                  : this.$store.getters['Auth/get_selected_offer'].fileDp
              "
              type="office"
            />
            <vue-friendly-iframe
              v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
              :src="
                this.$store.getters['Auth/get_selected_offer'].propTech
                  ? this.$store.getters['Auth/get_selected_offer'].fileLink
                  : this.$store.getters['Auth/get_selected_offer'].fileDp
              "
              frameborder="0"
              loading="lazy"
            ></vue-friendly-iframe>
          </v-card-text>
          <v-card-text style="height:62em !important;overflow:hidden" v-if="this.$store.getters['Auth/get_selected_offer'].document.documentType.status != 4">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
                "
                :value="this.$store.getters['Auth/get_selected_offer'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
                :src="this.$store.getters['Auth/get_selected_offer'].fileLink"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-section v-if="profile != 2 && this.$store.getters['Auth/get_selected_offer'].notice">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>Informations sur le Representant</v-card-title>
            <v-card-text>
              <v-divider class="mb-2"></v-divider>
              <b>Email :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repEmail }}
              <v-spacer class="my-1"></v-spacer>
              <b>Téléphone :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repPhone }}
              <v-spacer class="my-1"></v-spacer>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-8">
            <v-card-title>
              <h4>Avis à manifestation d’intérêts concerné</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-list-item class="block text-truncate">
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text text-truncate font-weight-bold"
                      v-text="this.$store.getters['Auth/get_selected_offer'].notice.object"
                    ></v-list-item-title>

                    <v-list-item-subtitle class="text-truncate secondary--text">{{
                      this.$store.getters["Auth/get_selected_offer"].notice.publiDate
                        | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h4>
                <!-- Ouverture de la  réponse à l’avis à manifestation d’intérêts-->
                {{ this.$store.getters["Auth/get_selected_offer"].notice.noticeType.description }}
              </h4>
              <v-spacer></v-spacer>
              <v-btn v-if="profile" @click="downloadOffer()" class="primary">
                <v-icon>mdi-download</v-icon>
                Télécharger
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height:62em !important;overflow:hidden">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
                "
                :value="this.$store.getters['Auth/get_selected_offer'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
                :src="this.$store.getters['Auth/get_selected_offer'].fileLink"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-section>
    <v-section v-if="profile != 2 && this.$store.getters['Auth/get_selected_offer'].document">
      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>Information sur le représentant du soumissionnaire de l’Offre/Proposition/Pli concerné</v-card-title>
            <v-card-text>
              <v-divider class="mb-2"></v-divider>
              <b>Nom du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repFirstname }}
              <v-spacer class="my-1"></v-spacer>
              <b>Prénoms du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repLastname }}
              <v-spacer class="my-1"></v-spacer>
              <b>E-mail du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repEmail }}
              <v-spacer class="my-1"></v-spacer>
              <b>Numéro de téléphone du représentant du soumissionnaire :</b>
              {{ this.$store.getters["Auth/get_selected_offer"].repPhone }}
              <v-spacer class="my-1"></v-spacer>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="mb-8">
            <v-card-title>
              <h4>Demande de Propositions concernée par la Proposition Technique</h4>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-list-item class="block text-truncate">
                  <v-list-item-content>
                    <v-list-item-title
                      class="primary--text text-truncate font-weight-bold"
                      v-text="this.$store.getters['Auth/get_selected_offer'].document.object"
                    ></v-list-item-title>

                    <v-list-item-subtitle class="text-truncate secondary--text">{{
                      this.$store.getters["Auth/get_selected_offer"].document.publiDate
                        | moment("dddd, Do MMMM YYYY à HH:mm:ss")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h4>
                Ouverture de la Proposition Technique  relative à la Demande de Propositions concernée 
               <!-- {{
                  this.$store.getters["Auth/get_selected_offer"].document.documentType.description
                }}-->
              </h4>
              <v-spacer></v-spacer>
              <v-chip
                outlined
                label
                class="mx-1"
                v-if="this.$store.getters['Auth/get_selected_offer'].propFin"
                >PF</v-chip
              >
              <v-chip
                label
                outlined
                class="mx-1"
                v-if="this.$store.getters['Auth/get_selected_offer'].propTech"
                >PT</v-chip
              >
              <v-spacer></v-spacer>
              <v-btn v-if="profile" @click="downloadOffer()" class="primary">
                <v-icon>mdi-download</v-icon>
                TELECHARGER LA PROPOSITION TECHNIQUE
              </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height:62em !important;overflow:hidden" v-if="this.$store.getters['Auth/get_selected_offer'].document.documentType.status == 4">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
                "
                :value="
                  this.$store.getters['Auth/get_selected_offer'].propTech
                    ? this.$store.getters['Auth/get_selected_offer'].fileLink
                    : this.$store.getters['Auth/get_selected_offer'].fileDp
                "
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
                :src="
                  this.$store.getters['Auth/get_selected_offer'].propTech
                    ? this.$store.getters['Auth/get_selected_offer'].fileLink
                    : this.$store.getters['Auth/get_selected_offer'].fileDp
                "
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
            <v-card-text style="height:62em !important;overflow:hidden" v-if="this.$store.getters['Auth/get_selected_offer'].document.documentType.status != 4">
              <VueDocPreview
                style="height:100%"
                v-if="
                  this.$store.getters['Auth/get_selected_offer'].fileType == 'doc' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'docx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xls' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'xlsx' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLS' ||
                    this.$store.getters['Auth/get_selected_offer'].fileType == 'XLSX'
                "
                :value="this.$store.getters['Auth/get_selected_offer'].fileLink"
                type="office"
              />
              <vue-friendly-iframe
                v-if="this.$store.getters['Auth/get_selected_offer'].fileType == 'pdf'"
                :src="this.$store.getters['Auth/get_selected_offer'].fileLink"
                frameborder="0"
                loading="lazy"
              ></vue-friendly-iframe>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-section>
  </v-container>
</template>
<script>
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";
import { mapActions } from "vuex";
import VueDocPreview from "vue-doc-preview";
import fileDownload from "js-file-download";
import { icp_request } from "../../../../config/axios";

export default {
  components: { DatePicker, VueDocPreview },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    file_for_dp:[],
    addi: {
      objet: "",
      publi_date: "",
      file: []
    },
    reason: "",
    confirmationDialog: false,
    offerU: {
      document: 0,
      repFirstname: "",
      repLastname: "",
      repTitle: "",
      repPhone: "",
      repEmail: "",
      file: [],
      file_dp: []
    },
    ppm: [],
    additif: false
  }),
  computed: {
    getRemaining() {
      if (this.$store.getters["Auth/get_selected_offer"].document) {
        if(this.$store.getters["Auth/get_selected_offer"].propFin){
          return moment(this.$store.getters["Auth/get_selected_offer"].openDatPropFin).add(1, 'hours');
        }else{
          return moment(this.$store.getters["Auth/get_selected_offer"].document.date_opening).from(
            new Date()
          );
        }
      }
      if (this.$store.getters["Auth/get_selected_offer"].notice) {
        return moment(this.$store.getters["Auth/get_selected_offer"].notice.deadline).from(
          new Date()
        );
      }
    }
  },
  created() {
    this.$store.dispatch("Auth/getOffer", this.id);
    this.$store.dispatch("Auth/getAllConfigHome");
    this.$store.dispatch("Auth/getSubmissionFoldNoti");
    let Virt = [];

    Virt = JSON.parse(localStorage.getItem("userData"));
    this.profile = Virt.profile.status;
    this.user_data = Virt;
    this.$store.dispatch("Auth/loadAllOffer");
  },
  methods: {
    UpdateOffer() {
      const data = new FormData();
      data.append("offer_id", this.id);
      data.append("rep_firstname", this.offerU.repFirstname);
      data.append("rep_lastname", this.offerU.repLastname);
      data.append("rep_title", this.offerU.repTitle);
      data.append("rep_phone", this.offerU.repPhone);
      data.append("rep_email", this.offerU.repEmail);
      data.append("file", this.offerU.file[0]);
      this.$store.dispatch("Auth/UpdateOffer", data);
    },
    UpdateDpFile() {
      const data = new FormData();
      data.append("document", this.$store.getters['Auth/get_selected_offer'].document.id);
      data.append("file", this.file_for_dp[0]);
      this.$store.dispatch("Auth/updateDpFile", data);
    },
    async downloadOffer() {
      const data = new FormData();
      data.append("id", this.id);
      await icp_request
        .post("offer/download", data, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
        .then(response => {
          this.$store.dispatch("Auth/Notify_Pop", response);
        });
    },
    // RemoveOffer() {
    //   const data = new FormData();
    //   data.append("offer_id", this.id);
    //   data.append("reason", this.reason);
    //   this.$store.dispatch("Auth/RemoveOffer", data);
    // },
    openConfirmationDialog() {
      this.confirmationDialog = true;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    confirmOfferRemoval() {
      // Perform offer removal logic here
      const data = new FormData();
      data.append("offer_id", this.id);
      data.append("reason", this.reason);
      this.$store.dispatch("Auth/RemoveOffer", data);

      // Reset the reason and close the dialog
      this.reason = "";
      this.confirmationDialog = false;
    },
  }
};
</script>
<style>
.w-62em {
  height: 62em !important;
  width: 100% !important;
}
.v-card__text {
  width: 100% !important;
}
.vue-friendly-iframe iframe {
  width: 100% !important;
  height: 62em !important;
}
.custom-center {
  display: flex;
  justify-content: center;
  margin-top: -10px;
}
</style>
